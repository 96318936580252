<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">紅利管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { callback(); }"
          @edit="(row, callback) => { callback(); }"
          @reset="(row, callback) => { callback(); }"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-member-id="{ data }">
                <SelectBox v-bind="memberSelectOptions" v-model="data.MemberId" />
              </template>
            </vxe-form>
            <div class="post intro-y overflow-hidden box mx-3 mt-2 border p-3">
              <div class="flex items-center ml-1 mb-3">
                明細
                <button
                  class="button text-theme-1 p-1 ml-auto mr-1"
                  title="新增項目"
                  @click="onAddItem(row)"
                >ki
                  <FontAwesome class="h-4" icon="plus" />
                </button>
              </div>
              <Grid
                ref="detailGrid"
                v-bind="detailGridOptions"
                @addNewRow="(row, callback) => { row.Time = new Date(); row.Amount = 0 ; row.AmountBalance = 0 ; row.BonusPoints = 0 ; row.BonusBalance = 0 ; callback(); }"
                @edit="(row, callback) => { callback(); }"
                @reset="(row, callback) => { callback(); }"
                @removeSelectedRows="hideBatchDropDown()"
              >
                <!-- <template #column-product-id="{ row: item }">
                  <span v-else-if="item.Product">{{ item.Product.Name }}</span>
                </template> -->
              </Grid>
            </div>
          </template>
          <!-- <template #modal-footer="{ row }">
            <vxe-button v-if="row" type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button v-if="row" type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template> -->
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(column, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, reactive, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import { VxeFormProps } from "vxe-table";
import { helper as $h } from "@/utils/helper";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [
      { field: "MemberName" },
      { field: "AccumulatedAmount" },
      { field: "BonusPoints" },
    ];

    const gridOptions = reactive<GridOptions>({
      id: "supplier",
      title: "紅利管理",
      multiselect: false,
      canCreate: false,
      canUpdate: true,
      canDelete: false,
      // toolbarConfig: { custom: true, refresh: true },
      printConfig: { sheetName: "紅利資訊清單", columns: printColumns, modes: ["current", "selected", "all"] },
      exportConfig: { filename: "紅利資訊清單", type: "csv", types: ["html", "csv"], mode: "all", modes: ["current", "selected", "all"], columns: printColumns },
      columns: [
        { field: "MemberName", title: "會員姓名", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'AccumulatedAmount', title: '累計消費金額', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'BonusPoints', title: '紅利點數', showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      // decideRowOperable: (row, operation) => row.Status < 30 || operation === 'read',
      promises: {
        query: model ? params => {
          return new Promise(resolve => {
              model.dispatch("memberAccountBook/query", params).then(async res => {
                const members = await model.dispatch("member/query", {
                  condition: new Condition(
                    "Id",
                    Operator.In,
                    res.data.map((e: any) => e.MemberId)
                  )
                });
                res.data.forEach((e: any) => {
                  e.MemberName = members.find(
                    (i: any) => i.Id === e.MemberId
                  ).Person.Name;
                });
                resolve(res);
              });
            });
        } : undefined,
        queryAll: model ? () => model.dispatch("memberAccountBook/query") : undefined,
        save: model ? (params) => model.dispatch("memberAccountBook/save", params) : undefined,
      },
      modalConfig: { width: "60%", height: "80%", showFooter: true }
    });

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'AccumulatedAmount', title: '累計消費金額', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入消費金額', type: "number" } } },
        { field: "BonusPoints", title: "紅利點數", span: 24, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入紅利點數" } } },
        { field: "Member", title: "會員", span: 24, slots: { default: "column-member-id" } },
        // { field: "Remark", title: "備註", span: 24, itemRender: { name: '$textarea', props: { placeholder: '請輸入備註', clearable: true }, attrs: { type: 'text' } } },
      ],
      rules: {
        AccumulatedAmount: [{ required: true }],
        BonusPoints: [{ required: true }],
        Member: [{ required: true }]
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      readonly: true,
      showSearch: true,
      rowId: "value",
      placeholder: "選擇會員",
      textField: "label",
      valueField: "value",
      columns: [
        {
          field: "label",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("member/find", value)
              .then(res => resolve({ label: res.Person.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    const detailGrid = ref<any>({});
    const detailGridOptions : GridOptions = {
      title: '操作紀錄',
      mode: "inline",
      canCreate: true,
      canDelete: false,
      sortConfig: { defaultSort: { field: "Time", order: "desc" } },
      multiselect: false,
      columns: [
        {
          field: "Time",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 160,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        { field: 'Name', title: '名目', showHeaderOverflow: true, showOverflow: true, sortable: true, editRender: { name: "$input", immediate: true } },
        { field: "BonusPoints", title: "紅利點數", showHeaderOverflow: true, showOverflow: true, align: "right", width: 150, sortable: true, editRender: { name: "$input", immediate: true, props: { type: "number" } }, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "Amount", title: "消費金額", showHeaderOverflow: true, showOverflow: true, align: "right", width: 150, sortable: true, editRender: { name: "$input", immediate: true, props: { type: "number" } }, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "BonusBalance", title: "紅利結餘", showHeaderOverflow: true, showOverflow: true, align: "right", width: 180, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) }
      ],
      editRules: {
        Name: [{ required: true, message: "未輸入文字" }],
        Amount: [
          { required: true, message: '未輸入單價' },
        ],
        BonusPoints: [
          { required: true, message: '未輸入單價' },
        ],
      },
      // decideRowOperable: (row, operation) => operation === 'update',
      promises: {
        query: model ? (params) => { params.condition = new Condition("AccountBookId", Operator.Equal, grid.value.editingRow?.Id || 0).and(params.condition!); return model.dispatch("memberAccountBookItem/query", params); } : undefined,
        queryAll: model ? () => model.dispatch("memberAccountBookItem/query") : undefined,
        save: model ? (params) => model.dispatch("memberAccountBookItem/save", params).then(async () => { grid.value.editingRow.Amount = (await model.dispatch('memberAccountBookItem/find', grid.value.editingRow.Id)).Amount; grid.value.refresh(); }) : undefined,
      },
      modalConfig: { height: "auto" },
    };

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions,
      detailGrid,
      detailGridOptions,
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onAddItem(masterRow: any) {
      if (!masterRow.Id) {
        if (!confirm("新增項目須先建立此張調整單，是否同意?")) return;
        try {
          const bill = await this.$model.dispatch("memberAccountBookItem/insert", masterRow);
          Object.assign(masterRow, bill);
        } catch (e: any) {
          this.$send('error', e);
        }
      }
      this.detailGrid.addNewRow({
        AccountBookId: this.grid.editingRow.Id,
      });
    },
  }
});
</script>
