
import CloudFun, { Condition, defineComponent, Operator, reactive, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import { VxeFormProps } from "vxe-table";
import { helper as $h } from "@/utils/helper";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [
      { field: "MemberName" },
      { field: "AccumulatedAmount" },
      { field: "BonusPoints" },
    ];

    const gridOptions = reactive<GridOptions>({
      id: "supplier",
      title: "紅利管理",
      multiselect: false,
      canCreate: false,
      canUpdate: true,
      canDelete: false,
      // toolbarConfig: { custom: true, refresh: true },
      printConfig: { sheetName: "紅利資訊清單", columns: printColumns, modes: ["current", "selected", "all"] },
      exportConfig: { filename: "紅利資訊清單", type: "csv", types: ["html", "csv"], mode: "all", modes: ["current", "selected", "all"], columns: printColumns },
      columns: [
        { field: "MemberName", title: "會員姓名", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'AccumulatedAmount', title: '累計消費金額', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'BonusPoints', title: '紅利點數', showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      // decideRowOperable: (row, operation) => row.Status < 30 || operation === 'read',
      promises: {
        query: model ? params => {
          return new Promise(resolve => {
              model.dispatch("memberAccountBook/query", params).then(async res => {
                const members = await model.dispatch("member/query", {
                  condition: new Condition(
                    "Id",
                    Operator.In,
                    res.data.map((e: any) => e.MemberId)
                  )
                });
                res.data.forEach((e: any) => {
                  e.MemberName = members.find(
                    (i: any) => i.Id === e.MemberId
                  ).Person.Name;
                });
                resolve(res);
              });
            });
        } : undefined,
        queryAll: model ? () => model.dispatch("memberAccountBook/query") : undefined,
        save: model ? (params) => model.dispatch("memberAccountBook/save", params) : undefined,
      },
      modalConfig: { width: "60%", height: "80%", showFooter: true }
    });

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'AccumulatedAmount', title: '累計消費金額', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入消費金額', type: "number" } } },
        { field: "BonusPoints", title: "紅利點數", span: 24, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入紅利點數" } } },
        { field: "Member", title: "會員", span: 24, slots: { default: "column-member-id" } },
        // { field: "Remark", title: "備註", span: 24, itemRender: { name: '$textarea', props: { placeholder: '請輸入備註', clearable: true }, attrs: { type: 'text' } } },
      ],
      rules: {
        AccumulatedAmount: [{ required: true }],
        BonusPoints: [{ required: true }],
        Member: [{ required: true }]
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      readonly: true,
      showSearch: true,
      rowId: "value",
      placeholder: "選擇會員",
      textField: "label",
      valueField: "value",
      columns: [
        {
          field: "label",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("member/find", value)
              .then(res => resolve({ label: res.Person.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    const detailGrid = ref<any>({});
    const detailGridOptions : GridOptions = {
      title: '操作紀錄',
      mode: "inline",
      canCreate: true,
      canDelete: false,
      sortConfig: { defaultSort: { field: "Time", order: "desc" } },
      multiselect: false,
      columns: [
        {
          field: "Time",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 160,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        { field: 'Name', title: '名目', showHeaderOverflow: true, showOverflow: true, sortable: true, editRender: { name: "$input", immediate: true } },
        { field: "BonusPoints", title: "紅利點數", showHeaderOverflow: true, showOverflow: true, align: "right", width: 150, sortable: true, editRender: { name: "$input", immediate: true, props: { type: "number" } }, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "Amount", title: "消費金額", showHeaderOverflow: true, showOverflow: true, align: "right", width: 150, sortable: true, editRender: { name: "$input", immediate: true, props: { type: "number" } }, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "BonusBalance", title: "紅利結餘", showHeaderOverflow: true, showOverflow: true, align: "right", width: 180, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) }
      ],
      editRules: {
        Name: [{ required: true, message: "未輸入文字" }],
        Amount: [
          { required: true, message: '未輸入單價' },
        ],
        BonusPoints: [
          { required: true, message: '未輸入單價' },
        ],
      },
      // decideRowOperable: (row, operation) => operation === 'update',
      promises: {
        query: model ? (params) => { params.condition = new Condition("AccountBookId", Operator.Equal, grid.value.editingRow?.Id || 0).and(params.condition!); return model.dispatch("memberAccountBookItem/query", params); } : undefined,
        queryAll: model ? () => model.dispatch("memberAccountBookItem/query") : undefined,
        save: model ? (params) => model.dispatch("memberAccountBookItem/save", params).then(async () => { grid.value.editingRow.Amount = (await model.dispatch('memberAccountBookItem/find', grid.value.editingRow.Id)).Amount; grid.value.refresh(); }) : undefined,
      },
      modalConfig: { height: "auto" },
    };

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions,
      detailGrid,
      detailGridOptions,
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onAddItem(masterRow: any) {
      if (!masterRow.Id) {
        if (!confirm("新增項目須先建立此張調整單，是否同意?")) return;
        try {
          const bill = await this.$model.dispatch("memberAccountBookItem/insert", masterRow);
          Object.assign(masterRow, bill);
        } catch (e: any) {
          this.$send('error', e);
        }
      }
      this.detailGrid.addNewRow({
        AccountBookId: this.grid.editingRow.Id,
      });
    },
  }
});
